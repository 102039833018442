import { createElement, memo, useEffect } from "react";
import { Link } from "react-router-dom";

const SignIn = memo(() => {
    
    useEffect(() => {
        const form = document.getElementById('sign-in');
        form.addEventListener('submit', saveArticle);

        async function saveArticle(event) {
            event.preventDefault(); // предотвратить стандартное поведение формы
            const myFormData = new FormData(form);
            await fetch("https://v2561009.hosted-by-vdsina.ru/api/v1/authorization/login", {
                method: "post",
                body: myFormData
            })
            .then((res) => {
                if (res.ok) {
                    window.location.replace('chat');
                    console.log(res)
                } else {
                    window.location.replace('/login');
                }
            })
            .catch((err) => window.location.replace('/login'));
        }

        return () => {
            form.removeEventListener('submit', saveArticle);
        };
    }, []);

    return (
        <div className="auth-lay" id="app">
            <form action="https://v2561009.hosted-by-vdsina.ru/api/v1/authorization/login" method="post" className="auth-card-lay" id="sign-in">
                <span className="auth-card-title">Вход</span>
                <span className="auth-card-def-txt">Никнейм пользователя:</span>
                <input name="username" />
                <span className="auth-card-def-txt">Пароль:</span>
                <input name="password" />
                <a href="">Запросить код</a>
                <span className="auth-card-def-txt">Двухфакторная защита:</span>
                <input name="otp_code" />
                <div className="auth-wrap-lay">
                    <span>Не удаётся войти?</span>
                    <Link to="/acceptlog">Восстановить доступ</Link>
                </div>
                <button className="login-btn" type="submit">Войти в аккаунт</button>
                <div className="auth-wrap-lay-2">
                    <span>Нет аккаунта?</span>
                    <Link to="/reg">Регистрация</Link>
                </div>
            </form>
        </div>
    );
});

export default SignIn;
