import { useState, memo, useEffect} from "react";
window.onload = () =>{
const switcher = document.getElementById('switcher');
const circle = document.getElementById('circle');

let isActive = false;

switcher.addEventListener('click', () => {
    isActive = !isActive;

    if (isActive) {
        circle.style.transform = 'translateX(100%)';
        switcher.style.backgroundColor = '#ccc'; // Зеленый цвет
        circle.style.backgroundColor = '#fff'; // Цвет кружка
    } else {
        circle.style.transform = 'translateX(0)';
        switcher.style.backgroundColor = '#1C66D1'; // Серый цвет
        circle.style.backgroundColor = '#fff'; // Цвет кружка
    }
});
}


const topNavBar = memo((props) => {
    



    const [prof_data, setProf_data] = useState('');
    const [rub, setRub] = useState('1');
    const [usd, setUsd] = useState('');
    const [pars_data, setPars_data] = useState('');

    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('http://178.20.44.234:8000/parser');
            const data = await response.json();
            setPars_data(data);
            console.log(data)
        };
        fetchUser();
    }, []);
    
    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/profile/getMe');
            const data = await response.json();
            
            setProf_data(data);
            console.log(data)
        };
        fetchUser();
    }, []);

    const exchangeRate = 75;


    const handleRubChange = (event) => {
        const value = event.target.value;
        setRub(value);
        if (!isNaN(value) && value !== '') {
            setUsd((value / exchangeRate).toFixed(2));
        } else {
            setUsd('');
        }
    };

    const handleUsdChange = (event) => {
        const value = event.target.value;
        setUsd(value);
        if (!isNaN(value) && value !== '') {
            setRub((value * exchangeRate).toFixed(2));
        } else {
            setRub('');
        }
    };
    

    function validateInput(value) {
        if (value.nativeEvent.inputType === "insertText") {
            if (isNaN(parseInt(value.nativeEvent.data))) {
                value.target.value = value.target.value.substring(0, value.target.value.length-1);
            }

        }
    }
    
    return(
        <div id="top_nav-bar_wrapper">
            <nav class="top_nav-bar">
                <img className="nav_img" onClick={() => window.location.replace('profile')}/>
                <div id="nav-bar_search">
                    <img src="/img/topNavBarPics/loop.svg" alt=""/>
                    <input type="text" placeholder="Введите номер сделки" id="deal_finder" onInput={validateInput}/>
                </div>
                <div className="params_wrap">
                    <span>ATM</span>
                    <div className="white_wrap">
                        <span>Kasikorn</span>
                    </div>
                    <div className="white_wrap">
                        <span>Bangkokbank</span>
                    </div>
                </div>
                <div className="params_wrap2">
                    <span>Сделки</span>
                    <div className="white_wrap2">
                        <span>318</span>
                    </div>
                    <span>Рубль</span>
                    <div className="white_wrap2">
                        <input 
                        type="number"
                        id="rub"
                        value={rub}
                        onChange={handleRubChange}
                        
                        style={{width: '100%',borderRadius: '6px',color: '#c5c4c4', textAlign: 'center'}}/>
                    </div>
                    <span>USDT</span>
                    <div className="white_wrap2">
                    <input 
                    type="number"
                    id="usd"
                    value={usd}
                    onChange={handleUsdChange}
                    placeholder="Введите доллары"
                    style={{width: '100%',borderRadius: '6px',color: '#c5c4c4', textAlign: 'center'}}/>
                    </div>
                    <span>Ручной курс</span>
                    <div className="white_wrap2">
                        <span>36.17</span>
                    </div>
                    
                </div>
                <div className="change_line">
                <div class="switcher" id="switcher" onClick={() => fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/profile/changeStatus', {method: 'POST'})}>
                    <div class="circle" id="circle"></div>
                </div>
                    <span>Оффлайн</span>
                </div>
                <div className="nav-prof">
                    <img/>
                    <span>{prof_data.username}</span>
                </div>
            </nav>
        </div>
    )});

export default topNavBar;