import { memo, useState, useEffect } from "react";
const TempPanel = memo((props) => {
    const [temp, setTemp] = useState([])
    useEffect(() => {
        const fetchUser = async () => {
            const GetUser = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/templates/getTemplates')
            const data = await GetUser.json()
            setTemp(await data)
            console.log(data)
        } 
        fetchUser()
    }, [])
        return (
            <div className="fast-wrap" >
            
            <div className="list-3-group">
                <span className="list-3-title">Группа 1</span>
                <img src="img/collapse.png"/>
            </div>
            {temp != [] && temp.map((item) => (<span className="list-3-item">{item.template}</span>))}
            </div>

            )}
);

export default TempPanel;