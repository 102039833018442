import { memo, useState , useEffect } from "react";
import ExchangePanel from "./PanelLays/ExchangePanel";
import ScorePanel from "./PanelLays/ScoreLay";
import CalcLay from "./PanelLays/CalcLay";
import GetLay from "./PanelLays/GetLay";
import TempPanel from "./PanelLays/TempLay";
import ComLay from "./PanelLays/ComLay";
import ReqLay from "./PanelLays/ReqLay";

const Panel = memo((props) => {
    const [data, setData] = useState(props.data)
    useEffect(() => {
        setData(props.data);
        console.log(data)
    }, [props.data]);
    const [openedPanel, setOpenedPanel] = useState('exchange'); // null означает, что ни один компонент не открыт

    const togglePanel = (panelName) => {
        setOpenedPanel(openedPanel === panelName ? null : panelName); // Закрыть, если панель уже открыта,

    };
    

    return (
        <div className="info-panel">
            <div className="info-panel" style={{width: '100%'}} >
            {data !== null &&
            <span className="cl-info">{ data.tid} | {data.first_name}</span>}
            <div className="select-info">
            <span className={openedPanel === "exchange" ? "chats-item-act" : "chats-item"} onClick={() => togglePanel("exchange")}>Обмен</span>
                <span className={openedPanel === "score" ? "chats-item-act" : "chats-item"} onClick={() => togglePanel("score")}>Счета</span>
                <span className={openedPanel === "temp" ? "chats-item-act" : "chats-item"} onClick={() => togglePanel("temp")}>Шаблоны</span>
                <span className={openedPanel === "req" ? "chats-item-act" : "chats-item"} onClick={() => togglePanel("req")}>Реквизиты</span>
                <span className={openedPanel === "calc" ? "chats-item-act" : "chats-item"} onClick={() => togglePanel("calc")}>Калькулятор</span>
                <span className={openedPanel === "com" ? "chats-item-act" : "chats-item"} onClick={() => togglePanel("com")}>Комментарии</span>
                <span className={openedPanel === "get" ? "chats-item-act" : "chats-item"}onClick={() => togglePanel("get")} >Выдача</span>
            </div>
            {openedPanel === "exchange" && (
                <ExchangePanel togglePanel={togglePanel} />
            )}
            {openedPanel === "score" && (
                <ScorePanel togglePanel={togglePanel} />
            )}
            {openedPanel === "calc" && <CalcLay togglePanel={togglePanel} />}
            {openedPanel === "get" && <GetLay togglePanel={togglePanel} />}
            {openedPanel === "temp" && <TempPanel togglePanel={togglePanel} />}
            {openedPanel === "com" && <ComLay togglePanel={togglePanel} />}
            {openedPanel === "req" && <ReqLay togglePanel={togglePanel} />}
        </div>
    </div>
    );
});

export default Panel;