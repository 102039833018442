import { memo, useState, useEffect} from "react"; // Не забудьте добавить useEffect
import Panel from "../FasrtnfoPanel";
// getTemplates



const ContextMenu = ({ position, onCopy, onDelete }) => {
    return (
        <div 
            style={{
                position: 'absolute',
                top: position.y,
                left: position.x,
                backgroundColor: 'white',
                border: '1px solid #ccc',
                boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',
                zIndex: 1000,
            }}
        >
            <div onClick={onCopy} style={{ padding: '10px', cursor: 'pointer' }}>Copy</div>
            <div onClick={onDelete} style={{ padding: '10px', cursor: 'pointer' }}>Delete</div>
        </div>
    );
};




const MainLay = memo(() => {
    useEffect(() => {
        const fetchUser = async () => {
            const GetUser = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/profile/getMe')
            console.log(await GetUser)
        } 
        fetchUser()
    }, [])
    
    const def = {
        messages: { messages: [{ text: '' }, { text: '' }] },
        first_name: ''
    };
    const [openedChatSection, setOpenedChatSection] = useState('Vip');
    const togglePanel = (section) => {
        setOpenedChatSection(openedChatSection === section ? null : section);
    };
    const [chats, setChats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [actChat, setActChat] = useState(null);
    const [mes, setMes] = useState(null);
    const [messageText, setMessageText] = useState('');
    const [actTic, setActTic] = useState(null)
    const changeAct = (chat_id) => {
        setActChat(actChat === chat_id ? null : chat_id);
    };
    const [delMes, setDelMes] = useState(null)





    const [menuPosition, setMenuPosition] = useState(null);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [currentMessage, setCurrentMessage] = useState(null);


    const handleRightClick = (event, item, tid) => {
        event.preventDefault(); // Предотвращаем стандартное контекстное меню
        setCurrentMessage(item); // Сохраняем текущее сообщение
        setDelMes(item)
        setActTic(tid)
        setMenuPosition({ x: event.pageX, y: event.pageY });
        setIsMenuVisible(true);
    };

    const handleCopy = async () => {
        if (currentMessage) {
            await navigator.clipboard.writeText(currentMessage.text);
            console.log('Copied!');
        }
        setIsMenuVisible(false);
    };

    const handleDelete = async() => {
        if (currentMessage) {
            
            const response = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/ticket/${actTic}/del`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json' // Указываем тип контента
                },
                body: JSON.stringify({ 'global_id': currentMessage.global_id }) // Преобразуем объект в строку JSON
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`); // Обработка ошибок
            }
            
            const res = await response.json(); // Получаем ответ в формате JSON
            console.log(response, res);

            
        }
        setIsMenuVisible(false);
    };

    const handleClick = () => {
        // Закрываем меню при клике вне его
        if (isMenuVisible) {
            setIsMenuVisible(false);
            setCurrentMessage(null); // Очищаем текущее сообщение
        }
    };
    useEffect(() => {
        const fetchChats = async () => {
            try {
                if (openedChatSection !== null){
                const response = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/${openedChatSection}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setChats(data);
                
                }else{
                    const response = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/All`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setChats(data);
                    
                }
                
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchChats(); // Изначально вызываем сразу
        const intervalId = setInterval(fetchChats, 1000);

        return () => clearInterval(intervalId); // Очищаем интервал при размонтировании
    }, [openedChatSection]);

    useEffect(() => {
        const fetchChat = async () => {
            if (actChat != null) {
                try {
                    const response = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/ticket/${actChat}`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setMes(data);
                } catch (err) {
                    setError(err.message);
                }
            }
        };
        fetchChat();
        const intervalId = setInterval(fetchChat, 1000);
        setMessageText('')
        return () => clearInterval(intervalId);
        
    }, [actChat]);

    const sendM = async (event) => {
        event.preventDefault(); // предотвращаем стандартное поведение формы
        const formData = new FormData(event.target);
        try {
            await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/ticket/${actChat}/sendmessage`, {
                method: "POST",
                body: formData
            });
            setMessageText('')
        } catch (err) {
            setError(err.message);
        }
        
    };

    function formatMessageDate(dateString) {
        const messageDate = new Date(dateString);
        const now = new Date();
        const isToday = messageDate.toDateString() === now.toDateString();
        const timeDifference = now - messageDate;
        const oneHour = 60 * 60 * 1000;
        const oneDay = 24 * oneHour;

        if (isToday && timeDifference < oneHour) {
            return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else if (isToday) {
            return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else if (timeDifference < oneDay) {
            return 'Вчера';
        } else {
            const hoursAgo = Math.floor(timeDifference / oneDay);
            return `${hoursAgo} ч. назад`;
        }
    }

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    

    return (
        <div className="main-lay">
        <div className="nav_pan">
        <img id="chat" className="panel_item" src="img/leftNavBarPics/chat-def.png" onClick={() => window.location.replace('chat')}></img>
        <img className="panel_item" src="img/leftNavBarPics/exchange.png" onClick={() => window.location.replace('exchange')}></img>
        <img className="panel_item" src="img/leftNavBarPics/acc-def.png" onClick={() => window.location.replace('acceptence')}></img>
        <img className="panel_item" src="img/leftNavBarPics/spam.jpg" onClick={() => window.location.replace('spam')}></img>
        <img className="panel_item" src="img/leftNavBarPics/referals.jpg" onClick={() => window.location.replace('referals')}></img>
        {/* <img className="panel_item" src="img/leftNavBarPics/lists.png" onClick={() => window.location.replace('lists')}></img> */}
        </div>
                <div className="chats">
                    <div className="transactions">Сделки</div>
                    <div className="slider_chat">
                        <div onClick={() => togglePanel("Own")} className={openedChatSection === 'Own' ?"chats-item-act" : "chats-item"} >Мои</div>
                        <div onClick={() => togglePanel("Reception")} className={openedChatSection === 'Reception' ?"chats-item-act" : "chats-item"}>Важные</div>
                        <div onClick={() => togglePanel("Vip")} className={openedChatSection === 'Vip' ?"chats-item-act" : "chats-item"}>VIP</div>
                    </div>
                    {chats.length !== 0 && chats.map((item) => (
                        <div className={actChat === item._id ? "chat-card-act" : "chat-card"} onClick={() => changeAct(item._id)}>
                        <div className="cart-lay-1">
                            <img/>
                            <div className="cart-lay-2">
                                <div className="cart-lay-3">
                                    <span className="cart-name">{item.first_name}</span>
                                    <span className="cart-data">{formatMessageDate(item.messages[0]["date"])}</span>
                                </div>
                                <span className="cart-mes">{item.messages[0].text}</span>
                            </div>
                        </div>
                        <div className={actChat === item._id ? "cart-lay-4-act" : "cart-lay-4"}>
                            <span>{item._id} | {item.first_name}</span>
                            <button>{item.status}</button>
                        </div>
                    </div>
                    ))}
                    
                </div>
                {actChat !== null ? <div class="chat-container">
                <div class="chat-header">
            <div className="chat-head-prof">
                <img/>
                <span>{mes !== null && mes.first_name}</span>
            </div>
            <div className="chat-info">
                <div className="info-1">
                    <div>Обратные: ❌</div>
                    <div>Депозит: {2460003}</div>
                    <div>Сделок: 10</div>
                </div>
                <div className="info-2">
                    <div>ex24 - live</div>
                    <div>ID: {mes !== null && mes.tid} </div>
                </div>
            </div>
        </div>
        <div class="chat-messages">
        {mes !== null && mes.messages.map((item) => (
            
                <>
                {item.type[0] === 'command' ?
                    <div className="command-block">
                        {item.text}
                    </div>
                :
                    <div 
                    onContextMenu={(e) => handleRightClick(e, item, mes.tid)} 
                    className={item.from === "user" ? "message user-message" : "message admin-message"} 
                    key={`message-${item.local_id}`}
                >
                    <div>
                        <div className="message-content">{item.global_id === 0 ? item.text + '(this message has been delete)' : item.text}</div>
                    </div>
                </div>}
                </>
            ))}

            {isMenuVisible && (
                <ContextMenu 
                    position={menuPosition} 
                    onCopy={handleCopy} 
                    onDelete={handleDelete} 
                />
            )}
            
            
        </div>
        <div id="endofchat"><p></p></div>

        <form autocomplete="off"  class="chat-input" id="sender" onSubmit={sendM}>
            <img className="addbtn" src="/img/input/add.png"/>
            <input  value={messageText} 
                onChange={(e) => setMessageText(e.target.value)} type="text" id="text" name="text" placeholder="Type your message..."/>
            <button  type="submit" id="sendBtn"><img className="sendbtn" src="/img/input/send.png"/></button>
        </form>

    </div>
     :
     <div className="help-mes">
        <span>Please, select a chat</span>
        </div>}
    {actChat !== null && <Panel data={mes}/>}
        </div>
    );
});

export default MainLay;
