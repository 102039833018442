import { memo, useState, useEffect } from "react";

const ExchangePanel = memo((props) => {
        return (

        <div className="fast-wrap" >

            <span className="copy">Копировать сделку</span>
            <span className="finished">Завершена</span>
            <span className="warn">Я выплачиваю</span>
            <div className="cl-text-info">
                <span className="second-cl-text-color">К получению</span>
                <span className="main-cl-text-color">246400</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Откуда</span>
                <span className="main-cl-text-color">USDT</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Куда</span>
                <span className="main-cl-text-color">THB</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Скидка</span>
                <span className="main-cl-text-color">-0.5</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Способ получения</span>
                <span className="main-cl-text-color">Доставка</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Курс приёма</span>
                <span className="main-cl-text-color">1.003</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Способ оплаты</span>
                <span className="main-cl-text-color">USDT ERC 20 / Bep 20</span>
            </div>
            <hr />


            <div className="cl-text-info">
                <span className="second-cl-text-color">Курс</span>
                <span className="main-cl-text-color">0.0284</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Маркер</span>
                <span className="main-cl-text-color">3404</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Курс приёма (Bybit)</span>
                <span className="main-cl-text-color"> </span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Source (Telegram)</span>
                <span className="main-cl-text-color">Не заполнено
                (ex24-live)</span>
            </div>
            <div className="cl-text-info">
                <span className="second-cl-text-color">Реквизиты</span>
                <span className="main-cl-text-color">Lorem ipsum dolor sit amet consectetur. Ut aenean ornare velit egestas.</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Время отправки реквизитов</span>
                <span className="main-cl-text-color">570 мин.</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Ссылка на карту</span>
                <span className="main-cl-text-color">Lorem ipsum dolor sit amet consectetur. Facilisis condimentum dictum eget eu nunc ut tortor.</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Отправил в приёмку</span>
                <span className="main-cl-text-color">140</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Принял платёж</span>
                <span className="main-cl-text-color">priem-alina</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Курьер</span>
                <span className="main-cl-text-color">Jason Phuket</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Сумма по чеку</span>
                <span className="main-cl-text-color">0</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">ACAP</span>
                <span className="main-cl-text-color">0</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Is Blocked</span>
                <span className="main-cl-text-color">0</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Disclaimer подтверждён</span>
                <span className="main-cl-text-color">0</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Статус</span>
                <span className="main-cl-text-color">Доставка</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">ID для файлов</span>
                <span className="main-cl-text-color">12981</span>
            </div>
            <hr />
            <div className="cl-text-info">
                <span className="second-cl-text-color">Ответсвенный</span>
                <span className="main-cl-text-color">Nazar Shamin</span>
            </div>
    </div>
    
            )}
);

export default ExchangePanel;