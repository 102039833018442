import { memo, useState, useEffect } from "react";
const Spamer = memo((props) => {
    const [req, setReq] = useState([]);
    const [marks, setMarks] = useState([]);
    const [searchId, setSearchId] = useState('');
    const [searchDate, setSearchDate] = useState('');
    const [searchFromTo, setSearchFromTo] = useState('');
    const [searchAmount, setSearchAmount] = useState('');
    const [searchComment, setSearchComment] = useState('');
    const [searchSdelka, setSearchSdelka] = useState('');

    // Функция для фильтрации данных
    const filteredMarks = marks.filter(mark => {
        return (
            mark._id.toString().includes(searchId) &&
            mark.date.includes(searchDate) &&
            `${mark.from} ${mark.to}`.includes(searchFromTo) &&
            mark.amount.toString().includes(searchAmount) &&
            mark.comment.includes(searchComment) &&
            mark._id.toString().includes(searchSdelka)
        );
    });
    // useEffect(() => {
    //     const form = document.getElementById('sell');
    //     form.addEventListener('submit', saveArticle);

    //     async function saveArticle(event) {
    //         event.preventDefault(); // предотвратить стандартное поведение формы
    //         const myFormData = new FormData(form);
    //         console.log([...myFormData.entries()]);
    //         await fetch("https://v2561009.hosted-by-vdsina.ru/api/v1/operations/addTransfer", {
    //             method: "POST",
    //             body: myFormData,
    //             headers: {
    //                 'Accept': 'application/json',
    //                 // 'Content-Type': 'multipart/form-data' // Не добавляйте этот заголовок, если используете FormData
    //             }
    //         })
    //         .then((res) => {
    //             if (res.ok) {
    //                 alert('Sell is sucsess')
    //                 console.log(res)
    //             } else {
    //                 alert('Sell is not sucsess')
    //                 console.log(res)
    //             }
    //         })
    //         .catch((err) => window.location.replace('/login'));
    //     }

    //     return () => {
    //         form.removeEventListener('submit', saveArticle);
    //     };
    // }, []);


    useEffect(() => {
        const form = document.getElementById('marker');
        form.addEventListener('submit', saveArticle);

        async function saveArticle(event) {
            event.preventDefault(); // предотвратить стандартное поведение формы
            const myFormData = new FormData(form);
            console.log([...myFormData.entries()]);
            await fetch("https://v2561009.hosted-by-vdsina.ru/api/v1/operations/addSell ", {
                method: "POST",
                body: myFormData,
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'multipart/form-data' // Не добавляйте этот заголовок, если используете FormData
                }
            })
            .then((res) => {
                if (res.ok) {
                    alert('Sell is sucsess')
                    console.log(res)
                } else {
                    alert('Sell is not sucsess')
                    console.log(res)
                }
            })
            .catch((err) => window.location.replace('/login'));
        }

        return () => {
            form.removeEventListener('submit', saveArticle);
        };
    }, []);


    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/reception/getRequisites');
            const data = await response.json();
            setReq(data);
        };
        fetchUser();
    }, []);
    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/operations/getOperations');
            const data = await response.json();
            console.log(data)
            setMarks(data)
        };
        fetchUser();
    }, []);
    return (
        <div className="main-lay">
            <div className="nav_pan">
            <img id="chat" className="panel_item" src="img/leftNavBarPics/chat-def.png" onClick={() => window.location.replace('chat')}></img>
            <img className="panel_item" src="img/leftNavBarPics/exchange-act.png" onClick={() => window.location.replace('exchange')}></img>
            <img className="panel_item" src="img/leftNavBarPics/acc-def.png" onClick={() => window.location.replace('acceptence')}></img>
            <img className="panel_item" src="img/leftNavBarPics/spam.jpg" onClick={() => window.location.replace('spam')}></img>
        <img className="panel_item" src="img/leftNavBarPics/referals.jpg" onClick={() => window.location.replace('referals')}></img>
            {/* <img className="panel_item" src="img/leftNavBarPics/lists.png" onClick={() => window.location.replace('lists')}></img> */}
            </div>
            <div className="exch-lay-wrap">
                <form className="spam-card-wrap" id="marker">  
                    <span className="spam-card-title">Добавить</span>
                    <span className="spam-card-it">Ссылка на сообщение</span>
                    <input name="marker" placeholder="Введите сумму" />
                    <span className="spam-card-it">To peer</span>
                    <input name="rate" placeholder="Введите сумму" />
                    <span className="spam-card-it">Сессия:</span>
                    <select name="card" >
                    {req.length > 0 && req.map((item) => (
                        <option value={item.title} key={item.id}>{item.title}</option>
                        
                    ))}
                    </select>
                    <span className="spam-card-it">Cron правило</span>
                    <input name="amount" placeholder="Введите сумму" />
                    
                    <button type="submit">Добавить</button>
                </form>
                <div className="exch-filt"> 
                    <div className="exch-filt-wrap" style={{gridTemplateColumns: '25% 25% 25% 25%'}}> 
                        <span className="exch-filt-title">Ссылка</span>
                        <span className="exch-filt-title">To peer</span>
                        <span className="exch-filt-title">Сессия</span>
                        <span className="exch-filt-title">Crontab</span>
                    </div>
                    <div className="exch-filt-wrap" id="search" style={{gridTemplateColumns: '25% 25% 25% 25%'}}> 
                <input 
                    id="id" 
                    placeholder="Поиск по ID" 
                    value={searchId}
                    onChange={(e) => setSearchId(e.target.value)}
                />
                <input 
                    id="date" 
                    placeholder="Поиск по дате" 
                    value={searchDate}
                    onChange={(e) => setSearchDate(e.target.value)}
                />
                <input 
                    id="from-to" 
                    placeholder="Поиск от-до" 
                    value={searchFromTo}
                    onChange={(e) => setSearchFromTo(e.target.value)}
                />
                <input 
                    id="amount" 
                    placeholder="Поиск по сумме" 
                    value={searchAmount}
                    onChange={(e) => setSearchAmount(e.target.value)}
                />
            </div>
            
            <div style={{ overflowY: 'auto' }}> 
                
                {filteredMarks.map((mark) => (
                    <>
                    <div className="exch-filt-wrap" key={mark._id} style={{gridTemplateColumns: '25% 25% 25% 25%'}}> 
                        <span className="exch-filt-txt">{mark._id}</span>
                        <span className="exch-filt-txt">{mark.date}</span>
                        <span className="exch-filt-txt">{mark.from} {mark.to}</span>
                        <span className="exch-filt-txt">{mark.amount}</span>
                        
                    </div>
                    <hr />
                    </>
                ))}
            </div>
                </div>
            </div>
            
        </div>
    )
});

export default Spamer;
