import { memo, useState, useEffect } from "react";

const ReqLay = memo((props) => {
    const [req, setReq] = useState([])
    useEffect(() => {
        const fetchUser = async () => {
            const GetUser = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/reception/getRequisites')
            .then(async (res) => setReq( await res.json()))
           
        } 
        fetchUser()
    }, [])

    return (
        <div className="fast-wrap" >
        {req != [] && req.map((item) => (

            
           <span class="list-4-item" id="list-4-item-1"> {item.title} </span>

        ))}

</div>
    )
});

export default ReqLay;
