import { memo, useEffect, useState } from "react";

const CalcLay = memo((props) => {
    const [openedPanel, setOpenedPanel] = useState('RUB-THB'); // null означает, что ни один компонент не открыт
    const [num, setNum] = useState('0');
    const [amount, setAmount] = useState('');
    const [wo_round, setWo_round] = useState(false);
    const [wo_delivery, setWo_delivery] = useState(false);
    const [is_fixed, setIs_fixed] = useState(false);

    const togglePanel = (panelName) => {
        setOpenedPanel(openedPanel === panelName ? null : panelName); // Закрыть, если панель уже открыта
    };

    const toggleNum = (Name) => {
        setNum(num === Name ? null : Name); // Закрыть, если панель уже открыта
    };

    useEffect(() => {
        const fetchData = async () => {
            if (openedPanel !== null && amount !== '') {
                try {
                    const response = await fetch("https://v2561009.hosted-by-vdsina.ru/api/v1/panel/calculate", {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json', // Установка заголовка
                        },
                        body: JSON.stringify({ // Преобразование в JSON
                            "course": openedPanel,
                            "amount": Number(amount),
                            "wo_round": wo_round,
                            "wo_delivery": wo_delivery,
                            "is_fixed": is_fixed,
                            "discount": Number(num),
                        })
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`); // Обработка ошибок
                    }

                    const data = await response.json(); // Преобразование ответа в JSON
                    console.log(data); // Выводим данные в консоль (можно удалить или заменить на нужные действия)
                } catch (error) {
                    console.error('Ошибка при отправке запроса:', error); // Логируем ошибку
                }
            }
            console.log({
                "course": openedPanel,
                "amount": Number(amount),
                "wo_round": wo_round,
                "wo_delivery": wo_delivery,
                "is_fixed": is_fixed,
                "discount": Number(num),
            });
        };

        fetchData(); // Вызов функции для получения данных
    }, [amount, wo_delivery, wo_round, is_fixed, openedPanel, num]);


    
    return (
        <div className="fast-wrap" >
                
                <div className="list-3-group">
                    <span className="list-3-title">Thailand</span>
                    <img src="img/collapse.png"/>
                </div>
                <div className="select-info">
                    <span className={openedPanel === "RUB-THB" ? "chats-item-act" : "chats-item"} onClick={() => togglePanel("RUB-THB")} >RUB ➡️ THB</span>
                    <span className={openedPanel === "USDT-THB" ? "chats-item-act" : "chats-item"} onClick={() => togglePanel("USDT-THB")}>USDT ➡️ THB</span>
                    <span className={openedPanel === "THB-RUB" ? "chats-item-act" : "chats-item"} onClick={() => togglePanel("THB-RUB")} >THB ➡️ RUB</span>
                    <span className={openedPanel === "USD-USDT" ? "chats-item-act" : "chats-item"} onClick={() => togglePanel("USD-USDT")} >USD ➡️ USDT</span>
                    <span className={openedPanel === "USDT-USD" ? "chats-item-act" : "chats-item"} onClick={() => togglePanel("USDT-USD")}>USDT ➡️ USD</span>
                    <span className={openedPanel === "THB-USDT" ? "chats-item-act" : "chats-item"} onClick={() => togglePanel("THB-USDT")}>THB ➡️ USDT</span>
                    <span className={openedPanel === "KZT-THB" ? "chats-item-act" : "chats-item"} onClick={() => togglePanel("KZT-THB")}>KZT ➡️ THB</span>
                    
                </div>
                <input placeholder="Введите сумму" onChange={(e) => setAmount(e.target.value)} className="list-5-inp"/>
                <div className="list-5-check">
                    <input onClick={() => setIs_fixed(!is_fixed)} type="checkbox"/>
                    <span>Обмен с фиксированной суммой</span>
                </div>
                <div className="list-5-check">
                    <input onClick={() =>setWo_delivery(!wo_delivery)} type="checkbox"/>
                    <span>Вычесть доставку</span>
                </div>
                <div className="list-5-check">
                    <input onClick={() =>setWo_round(!wo_round)} type="checkbox"/>
                    <span>Не округлять до 100</span>
                </div>
                <div className="list-3-group">
                    <span className="list-3-title">Скидка</span>
                </div>
                <div className="select-info">
                <span className={num === "0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("0")} >0</span>
                    <span className={num === "-0.5" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("-0.5")}>-0.5</span>
                    <span className={num === "-1.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("-1.0")}>-1.0</span>
                    <span className={num === "-1.5" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("-1.5")}>-1.5</span>
                    <span className={num === "-2.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("-2.0")}>-2.0</span>
                    <span className={num === "+0.5" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+0.5")}>+0.5</span>
                    <span className={num === "+1.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+1.0")}>+1.0</span>
                    <span className={num === "+1.5" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+1.5")}>+1.5</span>
                    <span className={num === "+2.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+2.0")}>+2.0</span>
                    <span className={num === "+3.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+3.0")}>+3.0</span>
                    <span className={num === "+4.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+4.0")}>+4.0</span>
                </div>
        </div>
    )
});

export default CalcLay;
