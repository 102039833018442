import { memo, useState, useEffect } from "react";



const ScorePanel = memo((props) => {
    const [sc, setSc] = useState([])
    useEffect(() => {
        const fetchUser = async () => {
            const GetUser = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/accounts/getAccounts')
            .then(async (res) => setSc( await res.json()))
           
        } 
        fetchUser()
    }, [])
        return (

            <div className="fast-wrap" >
           

        <div className="nav-bar_search">
            <img src="/img/topNavBarPics/loop.svg" alt=""/>
            <input type="text" placeholder="Введите номер сделки"/>
        </div>
        <div className="cl-text-info-l-2">
            <span className="main-cl-text-color">Карты</span>
            <span className="main-cl-text-color">Баланс</span>
            <span className="main-cl-text-color">Лимит</span>
        </div>
        <hr/>
        {sc != [] && sc.map((item) => (
                    <div className="cl-text-info-l-2">
                    <span className="second-cl-text-color">{item.title}</span>
                    <span className="main-cl-text-color">{item.balance}</span>
                    <span className="main-cl-text-color">{item.limit}</span>
                    </div>
        ))}
    </div>

            )}
);

export default ScorePanel;