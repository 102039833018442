import { memo, useState } from "react";

const ComLay = memo((props) => {
    // useEffect(() => {
    //     const form = document.getElementById('register');
    //     const qr = document.getElementById('register-1')
    //     qr.style.display = 'none'
    //     form.addEventListener('submit', saveArticle);
    //     const [com, setCom] = useState(null)
    //     const [inf, setInf] = useState(null)
    //     async function saveArticle(event) {
    //         form.style.display = 'none'
    //         event.preventDefault(); // предотвратить стандартное поведение формы
    //         const myFormData = new FormData(form);
    //         qr.style.display = 'grid'
    //         await fetch("https://v2561009.hosted-by-vdsina.ru/api/v1/authorization/register", {
    //             method: "post",
    //             body: myFormData
    //         })
            
    //         .then((res) => {
    //             if (res.ok) {
                    
    //                 console.log(res.json())
    //             } else {
                    
    //             }
    //         })
    //         .catch((err) => window.location.replace('/login'));
    //     }

    //     return () => {
    //         form.removeEventListener('submit', saveArticle);
    //     };
    // }, []);
    return (
        <form className="fast-wrap" id="add">
                
                <div className="list-3-group">
                    <span className="list-3-title">Информация по сделке</span>
                </div>
                <textarea name=""></textarea>
                <div className="list-3-group">
                    <span className="list-3-title">Информация по клиенту</span>
                </div>
                <textarea name=""></textarea>
        </form>
    )
});

export default ComLay;
